* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Myriad Pro";
}

body {
  overflow-x: hidden;
}

@font-face {
  font-family: 'Myriad Pro';
  src: local('MYRIADPRO-REGULAR'), url(./fonts/MYRIADPRO-REGULAR.woff) format('woff');
}
