.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logoImage {
    margin-bottom: 12px;
}

.headerBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
}

.iconBtn {
    height: 26px;
    width: auto;
    cursor: pointer;
}

.userAccountBtn {
    height: 42px;
    width: 42px;
    border: 1px solid white;
    border-radius: 50%;
    overflow: hidden;
    background-image: url('../../assets/backgrounds/background1.png');
    background-position: center center;
    cursor: pointer;
}

.menuBtn {
    display: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 16px;
}

.menuBtn img {
    height: 18px;
}

.mobileMenu {
    display: none;
}

@media (max-width: 900px) {
    .logoImage {
        width: 177px;
    }

    .headerBtns,
    .welcomeText {
        display: none;
    }

    .menuBtn {
        display: block;
    }

    .mobileMenu {
        position: fixed;
        top: -100px;
        left: 0;
        width: 100vw;
        display: block;
        padding: 4% 5%;
        background-color: #042e4e;
        transition: all 0.4s;
    }

    .mobileMenu nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .mobileMenu button {
        background: none;
        border: none;
        padding: 0;
        color: #299362;
        cursor: pointer;
        font-size: 18px;
        margin-right: 5vw;
    }

    .mobileMenu button.cancelBtn {
        height: 24px;
        margin-right: 0;
    }

    .expanded {
        top: 0;
    }
  }

  @media (max-width: 476px) {
    .mobileMenu {
        padding: 8%;
    }
}