.notificationContainer {
    position: relative;
    width: 100%;
    max-width: 842px;
    margin: 0 auto;
    height: 54px;
    margin-bottom: 30px;
}

.notification {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #ffffff;
    text-decoration: none;
}

.fade {
    position: absolute;
    background-color: black;
    opacity: 0.47;
    width: 100%;
    height: 100%;
}

.notification img {
    margin-right: 20px;
}

.slide {
    position: relative;
    height: 54px;
}

@media (max-width: 900px) {
    .notificationContainer {
        width: 90%;
        margin-bottom: 15px;
    }
    .notification {
        font-size: 18px;
    }
}