.createTileModal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.03s;
}

.isOpened {
    display: block;
}

.fade {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
}

.modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 0;
    width: 0;
    padding: 32px;
    background-color: #042e4e;
    z-index: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.modal .addBtn {
    border: none;
    cursor: pointer;
    background-color: #0eb35b;
    color: white;
    font-size: 20px;
    padding: 12px 18px;
}

.cancelBtn {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.isOpened .modal {
    height: fit-content;
    width: 400px;
}

.field  {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}

.field label  {
    color: gray;
    font-size: 20px;
    margin-bottom: 8px;
    transition: all 0.2s;
}

.field input {
    width: 100%;
    font-size: 20px;
    outline: none;
    margin-bottom: 20px;
    border: none;
    border-radius: 4px;
    caret-color: #0eb35b;
    border-bottom: 2px solid transparent;
    padding: 8px 10px;
    transition: all 0.2s;
}

.field input:focus {
    border-bottom: 2px solid #0eb35b;
}

.field input:focus + label {
    color: #0eb35b;
}

@media (max-width: 476px) {
    .modal {
        padding: 32px 16px;
        overflow-y: scroll;
    }

    .modal::-webkit-scrollbar {
        display: none;
    }

    .isOpened .modal {
        width: 100vw;
        height: 100vh;
    }

    .modal .addBtn {
        font-size: 16px;
        padding: 12px 18px;
    }

    .field label {
        font-size: 18px;
    }

    .cancelBtn {
        display: block;
        padding: 0;
    }

    .cancelBtn img {
        height: 32px;
        width: 32px;
    }
}