.searchContainer {
    display: flex;
    align-items: center;
    margin-top: 18px;
    background-color: white;
    padding-right: 11px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.searchContainer input {
    width: 100%;
    height: 58px;
    outline: none;
    font-size: 23px;
    padding: 2px 22px;
    border: none;
}

.searchContainer input::-webkit-input-placeholder {
    color: #858282;
}

.searchContainer img {
    width: 35px;
    height: 36px;
    cursor: pointer;
}

@media (max-width: 900px) {
    .searchContainer {
        margin-top: 5px;
    }

    .searchContainer input {
        font-size: 20px;
        padding: 2px 11px;
        height: 45px;
    }

    .searchContainer img {
        width: 21px;
        height: 22px;
    }
}